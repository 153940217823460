import Header from '../../shared/header/Header.vue'
import Footer from '../../shared/footer/Footer.vue'

export default {
  name: 'Postbacks',
  components: {
    Header,
    Footer
  }
}
